<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button/>
      <h5 class="pageTitle">Privacy Policy</h5>
    </div>
    <hr>


    <div class="msg">
      <div class="">
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>Our privacy commitment</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>In delivering Goblin Bank’s mission to help every student in the
          world love learning, we are committed to protecting the privacy of our users and providing a safe, online
          learning environment. Goblin Bank is committed to collecting only the bare minimum of personal information (or,
          “information”) needed to individualize a student’s learning and enable progress monitoring for parents and
          teachers.</p>
          <p>Note also Goblin Bank does not sell nor otherwise trade your personal information. Your information is only
            used to provide, improve and inform you about our services.</p>
          <p>This Privacy Policy describes the way in which MUNOVO LLC (“<strong>MUNOVO</strong>”,“<strong>Goblin Bank</strong>”,
            “<strong>we</strong>” or “<strong>us</strong>”) collects, uses, and safeguards personal information provided
            to us by students, parents, guardians, teachers and others (collectively “<strong>Users</strong>”) when they
            use our website applications and services we provide (collectively referred to as the
            “<strong>Services</strong>”).</p>
          <p>From time to time, we may change this privacy policy. If we do, we will post an amended version on this
            webpage. Please review this privacy policy periodically. Your continued use of the sites and services after
            any such amendment signifies your acceptance of those terms.</p></div>

        <div id="how-we-collect" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>1. How we collect and use
          information</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>We collect personal information when users visit our website
          or create an account to use Goblin Bank’s services. The type of personal information we collect varies
          depending on the type of user who engages our services, as detailed below:</p>
          <h3>Students</h3>
          <p>We collect personal information about student users when the student creates an account directly with us or
            when the student account is created by a parent or teacher. We collect the minimum amount of information
            about students using Goblin Bank’s services. The data we collect allows us to personalize students’ learning and
            to make progress monitoring for parents, teachers, and others easy and straightforward.</p>
          <p>When a Goblin Bank student account is created, we collect the student’s first name, last initial, location
            (country, state or province), grade, and password. </p>
          <h3>Parents</h3>
          <p>When registering with Goblin Bank, parents are asked to provide personal information, including but not limited
            to their first and last name, email address and a password for the account. Other information may be
            collected based on the type of account created, such as payment information or their child’s information in
            order to link the accounts. Note, Goblin Bank does NOT directly store credit card information — all credit card
            information and transactions are stored by our payment provider, <a target="_blank" rel="noopener"
                                                                                href="https://stripe.com/">Stripe</a>.
            Please <a target="_blank" rel="noopener" href="https://stripe.com/docs/security">click here</a> for more
            information about how Stripe processes customer information.</p>
          <h3>Teachers</h3>
          <p>Like parents, teachers are asked to provide personal information when registering for a Goblin Bank teacher
            account, such as first and last name, email address, a password for their account, school, grade level
            and/or curriculum (e.g., Common Core Math Standards). Other information may be collected depending on the
            type of account or teacher.<strong>&nbsp;</strong></p>


          <h3>Data that is no longer personal information</h3>
          <p>From the personal information processed from use of the Services, Goblin Bank may create aggregated (lots of
            combined different parts together), de-identified or anonymized data from what we have processed. What this
            means is we remove certain identifiers and delineative features of the data collected that make it
            personally identifiable to a particular User. We may use such aggregated, de-identified or anonymized data
            to support development or share it with third parties for lawful and legitimate business purposes, including
            to analyze, build and improve the Services and promote our business. When we share such information, it will
            not be shared in a way that could serve to identify you.</p>

          <h3>Information collected by technology — cookies &amp; other things</h3>
          <p>Like most websites and online services, Goblin Bank and our third-party providers automatically collect
            personal information to provide the most fulfilling experience when you visit our website, access our
            Services or otherwise engage with us.</p>
          <p>Generally, we collect such information through a variety of technologies, including cookies, web beacons,
            pixel tags and other similar technologies. We may use third-party providers to collect this information on
            our behalf. Using these tools, we can collect information about your device and its software, such as your
            IP address, browser type, a unique identifier that allows us to uniquely identify your browser, mobile
            device or your account, and other similar information. We also collect information about the way you use our
            website, products and services, including the pages you visit, the links you click, how frequently you
            access the product, or whether you open emails or click links contained in the emails we send you. We
            collect information about geographic location by analyzing other information, like an IP address, so we can
            associate a User with the appropriate curriculum. Geolocation data for Tutoring and/or marketing purposes
            may also be collected to support those services, provide notice of updates and new Services, and notify
            adult Users about the progress of their students. We may also collect analytics data, or use third-party
            analytics tools such as Google Analytics, to measure traffic and usage trends for the website and to
            understand more about the demographics and behaviors of our users.&nbsp;</p>
          <h3>Cookies</h3>
          <p>Cookies are small text files placed on your computer or mobile device when you visit our website. They
            allow the website to remember a user’s actions and preferences (such as login, language, avatar and others)
            over a period of time. Cookies can be classified according to their lifespan and the domain to which they
            belong. By lifespan, a cookie is either a:</p>
          <ul>
            <li><strong>Session cookie</strong> that is erased when the user closes their browser; or</li>
            <li><strong>Persistent cookie</strong> that remains on a user’s device for a pre-defined time.&nbsp;</li>
          </ul>
          <p>With regard to the domain to which a cookie belongs, there are:</p>
          <ul>
            <li><strong>First-party cookies</strong> set by the web server of the visited page and share the same domain
              (in this case goblinbank.com domain); or
            </li>
            <li><strong>Third-party cookies</strong> stored by a different domain from the visited page’s domain. This
              can happen if the webpage looks for a file outside of our domain.&nbsp;
            </li>
          </ul>
          <h3>Pixel tags/web beacons</h3>
          <p>A <strong>pixel tag</strong> (or a “<strong>web beacon</strong>”) is a piece of code used for our Services
            to collect information about how a user interacts with the web page. Pixel tags allow Goblin Bank to understand,
            in one example, if a user has been to a certain web page or clicked on a specific advertisement.&nbsp;</p>
          <h3>Advertising &amp; your choices</h3>
          <p>Our Services limit the use, and limit our partners’ use, of automatically collected information, such as IP
            addresses, cookie identifiers and other device identifiers. Automatically collected personal data is used
            for the limited purposes of supporting our Services to students and our internal business operations,
            including basic Services operations like providing access to the service and its features, individualizing
            content, and improving and optimizing Goblin Bank services. Note that contextual advertising is employed by
            Goblin Bank, and personal information is processed to help limit the number of times your child might see a
            particular ad. Goblin Bank takes multiple steps to avoid and limit the collection of data from <strong>our
              student Users</strong> and works to restrict targeted advertising from third parties. For example, Goblin Bank
            inhibits third-party ad networks from collecting data for targeted ads when a student user logs into their
            Goblin Bank account.</p>
          <p>And
            while contextual ads may appear in the Service while a child logs in from home, Goblin Bank does not allow
            interest-based advertising on those Services directed to our student Users.&nbsp;</p>
          <p>For Users other than our students, in certain instances Goblin Bank uses interest-based advertising from
            third-party partners and aggregate User data via our website to display interest-based ads on other
            websites, apps, services or devices you may use. Typically, though not always, these third parties use
            cookies, pixels and similar technologies to recognize your browser or device and collect data about your
            visit to our Service. Such collection allows for customizing content, ads and commercial messages relevant
            to your interests. This data also helps support our products and Services with ad-related information
            including reporting, analytics and market research. When this data is collected by Goblin Bank partners, it is
            done so in the aggregate, meaning the data is collected as a group and summarized to see trends, results and
            provide factually supported insights for Goblin Bank about what you are interested in and how well our Services
            perform. Any analysis does not identify anyone individually, but as a summary of a group of Users and their
            activities.&nbsp;&nbsp;</p>
          <p>To learn more about interest-based advertising and how you can opt-out or limit some online advertising and
            tracking, you may wish to review the following tools and options:</p>
          <ul>
            <li>Network Advertising Initiative (NAI) at <a target="_blank" rel="noopener"
                                                           href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a>
            </li>
            <li>Digital Advertising Alliance (DAA) at<a target="_blank" rel="noopener"
                                                        href="http://www.aboutads.info/choices">
              www.aboutads.info/choices</a> and <a target="_blank" rel="noopener"
                                                   href="https://youradchoices.com/appchoices">http://youradchoices.com/appchoices</a>
              and the mobile AppChoices app
            </li>
            <li>Apple operating system (iOS) settings on your mobile device, select “limit ad tracking”</li>
            <li>Android operating system settings on your mobile device, select “opt-out of interest-based ads”</li>
          </ul>
          <p>Please note that opting out of receiving interest-based advertising through the NAI’s and DAA’s online
            resources will only opt-out a user from receiving interest-based ads on that specific browser or device, but
            a user may still receive interest-based ads on their other devices. You must perform the opt-out on each
            browser or device you use.</p>
          <h3>Browser controls&nbsp;</h3>
          <p>For web browsers, your opt-out choices are often stored by a cookie, which means that blocking cookies may
            prevent our Services from operating as expected and prevent your choices from being stored. Note that
            blocking cookies on your computer will not affect your consent choices on a different device, such as a
            mobile device.&nbsp;</p>
          <p>To enrich Goblin Bank’s website content, we may embed videos from social media websites
            such as YouTube or Facebook. When we do this and you visit a page with that type of content embedded, you
            may be presented with cookies from these other websites. Please understand Goblin Bank has no control or
            liability with regard to cookies set from these external websites. Please check the relevant third party's
            cookie policy for more information.</p>
          <p>We also offer a 'share page' widget on some of our web pages, where content can be shared easily on the
            following sites - Facebook, Twitter, Instagram and LinkedIn. These sites may set a cookie when you log into
            their service. Again, Goblin Bank has no control or liability when it comes to cookies set by these third-party
            sites and you should check their cookie policies for more information.</p></div>
        <div id="how-we-use" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>2. How we use information</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>We use personal information for the following purposes:</p>
          <ul>
            <li>To allow you to register for and use the Services, such as when you securely log into your dashboards,
              to send a communication about a student’s progress or account, or provide feedback to us. For security
              purposes, information about a student is only provided to a parent and/or teacher connected to that
              student’s account.
            </li>
            <li>To measure a student’s progress and performance and thus adapt a student’s learning experience to that
              student’s needs.
            </li>
            <li>To analyze and provide progress reports to parents and teachers on connected student accounts.</li>
            <li>To process payment, communicate with you about your purchase and provide you with customer service.
              NOTE: Goblin Bank does NOT directly store credit card information - such information is stored by our payment
              provider, <a target="_blank" rel="noopener" href="https://stripe.com/">Stripe</a>. Please <a
                  target="_blank" rel="noopener" href="https://stripe.com/docs/security">click here</a> for more
              information about how Stripe processes your information.
            </li>
            <li>To provide technical notices, tutoring, updates, security alerts and customer support.</li>
            <li>To send marketing communications of potential interest to parents. You can choose to stop receiving
              emails from us by following the instructions contained in the email from us. We do not allow, however,
              “opt-out” for administrative messages when it comes to supporting your account or the account of a
              connected student, such as customer support, password reset or retrieval emails.
            </li>
            <li>To deliver our advertising to parents. Please note: Goblin Bank does not use information collected from
              students for targeted advertising to students.&nbsp;
            </li>
            <li>To assess and improve the products and services, educational content, improve the user experience,
              research, evaluate and improve the educational efficacy of the Services.
            </li>
            <li>To adapt, personalize and customize your experience by measuring student progress and adapting the
              Services to learning needs.
            </li>
            <li>To monitor, maintain, analyze and improve the functionality of the Services.</li>
            <li>As we believe may be necessary and appropriate to comply with applicable laws and regulations; to comply
              with legal process; to respond to requests from governmental authorities; to detect violations of and
              enforce our Terms &amp; Conditions; to protect the security of the website, products and services; to
              protect our rights, privacy, property, safety or that of you or others; and to allow us to pursue any
              available remedies or to limit damages that we may sustain.
            </li>
          </ul>
        </div>
        <div id="how-we-share" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>3. How we share information</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>We only share information collected in limited circumstances.
          We do not disclose the personal information of students to third parties for marketing or promotional
          purposes.</p>
          <p>We share information with third parties to:</p>
          <ul>
            <li>Perform certain services for us such as analytics, credit card processing, conduct research or evaluate
              the products and/or services, provided those service providers agree to contractual protections to
              safeguard the information, keep it confidential and use it only for the purposes permitted by our
              agreements;
            </li>
            <li>Carry out work on our behalf, including legal and financial advisors;</li>
            <li>Respond to a request from a court, law enforcement agency, or other government entity if we believe such
              is in accordance with or required by law;
            </li>
            <li>Safeguard our users and our company where we believe certain actions are inconsistent with our Terms
              &amp; Conditions or other agreements or policies, or to protect the rights, property and safety of Goblin Bank
              or a third party;
            </li>
            <li>Inform or support negotiations of a possible merger, sale, financing or transfer of all or a portion of
              our business to another entity; and/or
            </li>
            <li>Support inquiries either at your direction or with your informed consent.</li>
          </ul>
          <p>We may, as permitted by applicable law, use or disclose de-identified data and/or aggregated data (and thus
            not personal information) for research purposes, distribution to outside research partners or the
            preparation of and distribution of reports containing aggregate information and/or data provided that no
            individual or information about an individual can be identified with such data.</p></div>
        <div id="how-we-store" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>4. How we store and protect user
          information</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>The information we collect is stored and processed in the
          United States in environments designed to ensure only authorized individuals have access to the servers where
          information is stored. Because we understand and respect the trust placed in us to properly protect your
          information, Goblin Bank takes great care to maintain commercially reasonable standards of data security and
          protection, and continues to implement those measures for our servers in the United States, Use of our
          website, of our products or services outside of the United States, indicates to us that you understand our
          processing of data occurs in the US, that you approve of our standards of care and also consent to have your
          data transferred to and processed in the United States.</p>
          <p>Goblin Bank maintains strict administrative, technical and physical measures designed to safeguard the
            information we process. We use industry-standard SSL encryption to transfer Personal Information. Other
            safeguards include but are not limited to data encryption, firewalls, two-factor authentication and
            physical-access controls.</p>
          <p>When an account is created, the account holder creates a password. You can protect against unauthorized
            account access by selecting and protecting passwords appropriately, including using unique, multi-character
            passwords, ensuring devices are only accessed by those authorized to use them and taking care to sign out of
            online accounts (not just Goblin Bank, but all online accounts) after you or your student Users are finished
            using them. Access to personal information associated with User accounts is limited to those Goblin Bank staff
            members who require such access to support our Services.</p>
          <p>There is absolutely no guarantee that digital or online services are always 100% secure and protected. No
            information security program or safeguards can be guaranteed to be perfect or impenetrable. We strive to
            follow industry best practices and to continuously improve our information security measures, but we cannot
            guarantee the absolute security of your information. We certainly are not and cannot be responsible for the
            security of information you transmit to the products or Services over networks that we do not control,
            including internet and wireless networks. Please take care to safeguard your own networks and systems, both
            at school and at home.</p></div>
        <div id="users-rights" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>5. User's rights regarding their data</h2>
        </div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><h3>Access, correction or deletion of information</h3>
          <p>Teacher and parent Users and administrators have the right to access, correct or delete their personal data
            which is collected and used by Goblin Bank. Personal data can be accessed and changed by contacting our customer
            support or via our privacy department through the contact information set out below (How to Contact Us).</p>
          <p>Teacher and parent Users may contact us at any time to request access to, deletion or correction of any
            personal information we have collected from or about student Users associated with their account, including
            a request to us to cease collecting personal information from those student Users. If a student has an
            active account, a deletion request from a teacher will not be completed as that student has established the
            account at home as well. Such requests can be addressed through our customer support department or via our
            privacy department through the contact information set out below (How to Contact Us).</p>

          <p>Please be aware a request to delete personal information may lead to closing of your account or the
            inability to use the products or Services or certain portions of the products or Services.</p>
          <p>When a requested correction, deletion or other change is made Goblin Bank will make good faith efforts to
            effect the requested change as soon as reasonably practicable, usually within 7-14 business days. Please be
            aware some information may remain in an archive or backup record and Goblin Bank may retain certain data to
            support security or other actions including but not limited to account recovery, preventing abuse and/or
            fraud, legitimate business purposes, or if required by law. We may also retain de-identified or aggregate
            information from which all direct and indirect identifiers have been removed. Any data that is retained will
            continue to be subject to the Privacy Policy that is in effect at the time.</p>
          <h3>Opting out of email communications</h3>
          <p>You can opt out of receiving commercial or promotional emails from Goblin Bank by following the unsubscribe
            instructions indicated in the email, by contacting customer service or by contacting our privacy department.
            Opt-out via the link in our Goblin Bank email to you is the most efficient and direct means of removing your
            email from our commercial or promotional email lists. Please note we cannot unsubscribe or provide an
            “opt-out” for non-promotional messages regarding your account, such as account verification, updates to
            features of the Service, or technical or security notices.</p>
          <h3>Canceling a Premium Membership</h3>
          <p>Teachers and parents may cancel their premium memberships by contacting customer support. Please note we
            retain a User’s account data after receiving a cancellation request.</p>
          <h3>Data retention</h3>
          <p>&nbsp;We automatically delete or de-identify personal data after an account has been inactive for a set
            period of time, in accordance with our standard data retention policy, even if we do not receive a deletion
            request.&nbsp;</p>
          <h3>California privacy rights</h3>
          <p>&nbsp;Subject to certain limitations, the California Consumer Privacy Act (“CCPA”) provides California
            residents with specific rights regarding their personal information. For the purposes of this section,
            personal information has the meaning set out in the CCPA and does not include information that is publicly
            available, that is de-identified or aggregated. This section does not apply to information we process on
            behalf of an educational institution customer pursuant to contractual restrictions to process the
            information solely on behalf of the institution as an educational service provider.&nbsp; This section
            describes the rights applicable to California residents and explains how to exercise those rights. Under the
            CCPA, California residents have the following rights:</p>
          <ul>
            <li>Right to request or access personal information. You may be entitled to receive the personal information
              that we hold about you.
            </li>
            <li>Right to delete. You may be entitled to request that we delete the personal information that we have
              collected from you, subject to certain exceptions. If you request that we delete personal information we
              will take commercially reasonable steps to comply with that request in accordance with applicable laws.
            </li>
            <li>Right to opt out. Under the CCPA, you have the right to opt out of any sales of personal information
              that may be occurring or may occur in the future. Note that <strong>Goblin Bank does not sell, lease or
                otherwise trade for value any personal information</strong>. As such, there is no need to opt out as the
              sale or trade of data is not applicable to our products and Services.
            </li>
            <li>Right to not be discriminated against. You have the right to be free from discrimination for exercising
              these rights. Please note, that if the exercise of these rights limits our ability to process personal
              information, we may no longer be able to provide you with our products and services.
            </li>
          </ul>
          <p>To exercise these rights, please submit an email request to privacy@goblinbank.com with the subject line,
            “California Rights Request.” We will need to verify your identity before processing your request, which may
            require additional personal information from you, or that you log into your Goblin Bank account. In certain
            circumstances, we may decline or limit the scope of our compliance with your request, particularly where we
            are unable to verify your identity or locate your information, or as permitted by law.</p>
          <p><strong>&nbsp;</strong></p>
          <h3>Users in the European Economic Area (EEA), United Kingdom, and Switzerland</h3>
          <p>If you are a resident of the EEA, UK or Switzerland, the following information applies with respect to
            personal information (or personal data) collected through your use of our Services. As noted above, Goblin Bank
            processes personal information consistent with our disclosures in this Privacy Policy.</p>
          <p>We process personal information on the following legal bases:</p>
          <ul>
            <li>With your consent</li>
            <li>As necessary to Goblin Bank to perform its obligations under any agreement for Services</li>
            <li>As necessary for our legitimate business interests to provide the Services where those interests do not
              override your fundamental rights and freedoms related to data privacy
            </li>
          </ul>
          <p>Note, the information we collect may be transferred, stored and processed in Canada and the United States
            or any other country where we or our processors maintain offices. We will work to ensure transfers of
            personal information to a third country or an international organization are subject to appropriate
            safeguards under applicable law.</p>
          <p>You are entitled to the rights under Chapter III of the EU General Data Protection Regulation and the UK
            GDPR, or Section 2 of the Swiss Federal Act on Data Protection concerning the processing of your
            information, which includes rights to access, rectification and to deletion. To make a request or otherwise
            exercise your rights, please contact us at privacy@goblinbank.com. To honor such a request, we will need to
            verify your identity and residency, which may require additional supporting information.</p></div>
        <div id="how-we-protect" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>6. How we protect student data and comply
          with data privacy laws</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>Helping our Users safely navigate Goblin Bank’s web and app
          content is one of our top priorities. Our platform is used in large part by student Users in the United
          States, and as such, our Services are developed in compliance with several US laws, in particular the
          Children’s Online Privacy Protection Act, or COPPA. In support of this and other of Goblin Bank’s privacy and
          security work, note that regardless of your region of use, whether California, Texas or India, <strong>Goblin Bank
            requires parental permission from a student User to create an account and access Goblin Bank’s
            Services.</strong> As a parent or guardian, you will be prompted to give parental consent.&nbsp;</p>
          <p>International regulations that require adult consent include but are not limited to the following:</p>
          <ul>
            <li>Child Online Privacy Protection Act (COPPA) – United States</li>
            <li>General Data Protection Regulation (GDPR) – European Union</li>
            <li>General Personal Data Protection Act (LGPD) – Brazil&nbsp;</li>
          </ul>
          <h3>Providing consent &amp; COPPA</h3>
          <p>If your family member is a Goblin Bank user age 12 or younger, they will be prompted during registration
            and throughout service use online to request parental consent by entering an adult’s email. If they have
            requested your consent, you will receive an email with a link directing you to our consent page to provide
            your verified parental consent, as well as to sign into or create your adult Goblin Bank account.&nbsp;</p>
          <p>You will need to provide consent for each student User in your family. This allows direct confirmation each
            student User has obtained approval and you, as their adult, have notice of their Goblin Bank use. As the
            privacy laws evolve and change, Goblin Bank may modify this requirement.</p>
          <h3>Goblin Bank &amp; FERPA</h3>
          <p>Goblin Bank’s Terms and Conditions and this Privacy Policy apply to all schools, school districts, consortiums,
            etc., which include but are not limited to administrators and teachers who use the Services on their behalf
            (collectively, the “School”). Goblin Bank understands and works to comply with the Family Educational Rights and
            Privacy Act (FERPA), Protection of Pupil Rights Amendment (PPRA), as well as COPPA. We provide Services as
            an outsourced school function under FERPA 34 CFR Part 99.31(a)(1). We strongly advise Schools to provide
            notice to a student User’s adult about the School’s use of our Services in addition to a link or copy of
            this Privacy Policy.</p>
          <p>The information shared with Goblin Bank from Schools’ Education Records (defined below) continue to be owned
            and controlled by the School. Schools own and are solely responsible for Education Records. Education
            Records are records: (1) directly related to a student; and (2) maintained by the School or a party acting
            for the School (the “Records”). Goblin Bank does not own, control, or license Records, except as permitted under
            agreement with the School, this Privacy Policy and our Terms and Conditions.</p>
          <p>Under FERPA, Goblin Bank is a “school official” with a legitimate educational interest and may access Records
            to provide our Services to student, teacher or administrative Users. Adults (parents and guardians) may
            choose to consent to their student’s use of Goblin Bank, which in effect will cover the entirety of the
            student’s work in the Services, whether in school or at home. Note: Schools may create Goblin Bank accounts for
            student Users, using Records to pre-populate names and contact information, giving Goblin Bank needed access for
            the Services. Goblin Bank treats pre-populated data as School confidential information; meaning Goblin Bank will not
            disclose or use it, except as required or allowed to under agreement with the School, this Privacy Policy
            and our Terms and Conditions.</p>
          <p>Once verified parental consent is provided for a student User, information about that student (including
            data pre-populated by Schools) belongs to those students and/or their parents or guardians. With consent,
            such data is approved by a parent or guardian for both home and at School use, encompassing our processing
            of that data under this Privacy Policy. Goblin Bank will only use and access such Records as necessary for our
            educational Services to you, your students, teachers and administrators, and only for authorized purposes in
            accordance with our agreement with the School, this Privacy Policy and our Terms and Conditions. For clarity
            and without limitation, Goblin Bank does not (and will not allow third parties to) use personal information from
            Records to engage in targeted advertising. We do not sell, rent, share, or re-disclose personal information
            from Records to other parties unless authorized by you or under FERPA.</p>
          <p>Note Record data that has been<strong> </strong>anonymized, aggregated, or otherwise de-identified such
            that the data cannot reasonably identify a particular User or School (“De-ID’d Data”), may be processed by
            Goblin Bank, including contextual or transactional data about a User’s access and use of the Services. Such
            De-ID’d Data de-linked from FERPA-protected information may be used by Goblin Bank for other purposes including
            analysis, improvements, operations, and the provision or marketing of our Services.&nbsp;</p>
          <h3>Withdrawing consent</h3>
          <p>To withdraw consent from an account, please email privacy@goblinbank.com or reach out to Goblin Bank’s
            customer service.</p>
          <h3>Goblin Bank’s work on data privacy</h3>
          <p>We are in the process of acquiring iKeepSafe Privacy California Student Badge to signify that we have reasonable policies and practices surrounding the
            processing of personal information from children consistent with each of the following program
            guidelines:</p>
          <ul>
            <li>The Children's Online Privacy Protection Act <a target="_blank" rel="noopener"
                                                                href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule">(COPPA)</a>
            </li>
            <li>The Family Education Rights and Protection Act <a target="_blank" rel="noopener"
                                                                  href="http://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html">(FERPA)</a>
            </li>
            <li><a target="_blank" rel="noopener"
                   href="https://leginfo.legislature.ca.gov/faces/billNavClient.xhtml?bill_id=201320140SB1177">The
              Student Online Personal Information Protection Act</a></li>
            <li><a target="_blank" rel="noopener"
                   href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=EDC&amp;sectionNum=49073.1.">California
              AB 1584 — Privacy of Pupil Records: 3rd-Party Digital Storage &amp; Education Software (California
              Education Code section 49073.1)</a></li>
          </ul>
        </div>
        <div id="updates" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>7. Updates to this policy</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>We are committed to keeping your data private and secure, and
          as technology, privacy laws, and best practices evolve over time, we will do our best to keep this Privacy
          Policy up to date.</p>
          <p>For minor changes which do not reduce your rights under this policy, we will notify our Users via our
            website and by noting the Last Modified date at the bottom of this policy. For more significant changes, we
            will notify our users directly by email if possible, using the email on file, if provided.</p>
          <p>Schools with a Goblin Bank contract will be notified in advance of any material changes to privacy policies
            concerning the processing of student data, including practices around new or additional data collection, or
            those that may lessen our prior protections around student-data privacy.&nbsp;</p>
          <p>We encourage all Users to review this Privacy Policy from time to time and send us their questions or
            concerns. If you do not agree with our updates, you have the option of terminating your account and
            discontinuing use of our Services. However, note your continued use of our Services after a change to this
            Privacy Policy signals to Goblin Bank that you agree to accept those changes and the terms of the updated
            Privacy Policy in their entirety.</p></div>
        <div id="contact-us" class="styles__Group-sc-1h020sh-2 gdSwSe"><h2>8. How to contact us</h2></div>
        <div class="styles__Group-sc-1h020sh-2 gdSwSe"><p>If you wish to request access to personal information about
          you, request a correction about your information or have questions about this Privacy Policy, you may contact
          us at privacy@goblinbank.com, by mail at 10042 Wood Sorrels Ln, Burke, VA, USA, 22015.</p>
          <p><strong>Last Modified Date: January 5, 2022</strong></p></div>
      </div>
    </div>


  </div>
</template>

<script>


export default {
  name: 'PrivacyPolicy',
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    document.title = "Privacy Policy";
  }
}
</script>

<style scoped>
.msg {
  margin: 20px 10px 10px 10px;
  padding: 10px;
  font-size: 13px;
}

section {
  margin-bottom: 30px;
}

hr {
  margin-top: 5px;
}


</style>